/* TwoColumnLayout.css */
.two-column-container.p60 {
  padding-top: 60px;
}
.column.text-column h2 {
  font-size: 34px;
  text-decoration: underline;
  text-decoration-color: #cb1353;
  margin: 5px;
}
.two-column-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .column {
    flex: 1;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .image-column {
    background-color: #f9d23f; /* Set a background color for the image column */
  }
  
  .responsive-image {
    width: 100%;
    max-width: 100%; /* Ensure the image doesn't exceed its natural size */
  }
  
  .text-column {
    background-color: #ffffff;
    margin: auto;
}
 
.two-column-container button {
  background: linear-gradient(to right, #cb1353, #ee5322); /* Example gradient colors */
  color: #fff; /* Example text color */
  padding: 10px 20px;
  font-size: 1rem;
  font-family: cursive;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  /* Add any additional styles as needed */
}

.two-column-container button:hover {
  background: linear-gradient(to right, #ee5322, #cb1353); /* Example gradient colors on hover */
}
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .column {
      flex: 100%;
    }
  }
  


