.video-section {
    position: relative;
    width: 100%;
    height: auto; 
    overflow: hidden;
  }
  
  .video-player {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  
  @media only screen and (max-width: 767px) {
  .video-section {
    height: 309px; 

   
  }}