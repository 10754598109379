/* CopyrightText.css */
.copyright-container.p-60 {
  margin-top: 60px;
}

.copyright-container {
    background-color: #000000; /* Set a background color for the footer */
    color: #fff; /* Set text color */
    text-align: center;
    padding: 15px;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .copyright-text {
    font-size: 18px;
    margin: 0;
  }
  .copyright-text span {
   color: #fff;
  }
  .copyright-text span a{
    color: #ee5322;
}
.copyright-text span a:hover{
  color: #ffffff;
}
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .copyright-container {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
    .copyright-text {
      padding: 5px;
      margin-right: 35px;
  }
  }
  