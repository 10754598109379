/* NavBar.css */
.logo img {
  margin-right: 0px !important;
}
li.menuactive a {
    color: #f9d23f;
    text-decoration: underline;
    font-weight: 600;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    padding: 1rem;
    position: relative;
  }
  
  .logo {
    color: white;
    font-size: 1rem;
  }
  
  .menu-icon {
    display: none;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .nav-items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-items li {
    margin-right: 2.5rem;
  }
  
  .nav-items a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s;
  }
  
  .nav-items a:hover {
    color: #cb1353; /* Change color on hover */
  }
  
  /* Media query for tablets and larger screens */
  @media only screen and (min-width: 768px) {
    .navbar {
      padding: 0.2rem 2rem;
    }
  
    .menu-icon {
      display: none;
    }
  
    .nav-items {
      display: flex;
    }
  }
  
  /* Mobile responsiveness */
  @media only screen and (max-width: 767px) {
    .navbar {
      flex-direction: row;
      align-items: flex-start;
      padding: 0.8rem;
    }
  
    .menu-icon {
      display: block;
      z-index: 1;
    }
  
    .nav-items {
      display: none;
      flex-direction: column;
      width: 100%;
      text-align: center;
      position: absolute;
      background-color: #333;
      top: 60px;
      left: 0;
      border-top: 1px solid #555; /* Add a border between menu icon and items */
    }
  
    .nav-items.open {
      display: flex;
    }
  
    .navbar.open .bar:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
  
    .navbar.open .bar:nth-child(2) {
      opacity: 0;
    }
  
    .navbar.open .bar:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  
    .nav-items li {
      margin: 0;
      margin-bottom: 0.5rem;
    }
  
    .nav-items a {
      font-size: 1.2rem;
    }
  }
  