/* AboutUsSection.css */
.about-us-section.p-60 {
  padding-top: 60px;
}

.image-container img {
    width: 300px;
    height: 185px;
    border-radius: 20px;
    padding: 4px;
    background-color: #f9d23f;
}
.about-us-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
  }
  
  .image-section {
    position: absolute;
    top: 50%;
    left: 10%; /* Adjust the left position as needed */
    transform: translate(-50%, -50%);
  }
  
  .circle-image {
    width: 30%; /* Adjust the width as needed */
    height: auto;
    border-radius: 50%; /* Make the images circular */
    position: absolute;
    transform-origin: 50% 100%;
  }
  
  .circle-image:nth-child(1) {
    transform: rotate(-45deg) translateY(-100%);
  }
  
  .circle-image:nth-child(2) {
    transform: rotate(45deg) translateY(-100%);
  }
  
  .circle-image:nth-child(3) {
    transform: rotate(180deg) translateY(100%);
  }
  
  .text-section {
    flex: 1;
    padding: 20px;
    background-color: #f4f4f4; /* Add a background color for the text container */
    border-radius: 8px;
    text-align: center;
  }
  
  .text-container h2 {
    font-size: 34px;
    margin-bottom: 10px;
    text-decoration: underline;
    text-decoration-color: #cb1353;
}
  
  .text-container p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
 
.text-container button {
    background: linear-gradient(to right, #cb1353, #ee5322); /* Example gradient colors */
    color: #fff; /* Example text color */
    padding: 10px 20px;
    font-size: 1rem;
    font-family: cursive;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    /* Add any additional styles as needed */
  }
  
  .text-container button:hover {
    background: linear-gradient(to right, #ee5322, #cb1353); /* Example gradient colors on hover */
  }

  @media (max-width: 768px) {
    .about-us-section {
      display: unset;
  }
  }
  