/* HeroSlider.css */
.hero-slider.p-60 {
  padding-top: 60px;
}
.hero-slider {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  
  .slider-item {
    position: relative;
    width: 100%;
    height: 320px;
}
  
  .slider-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity of the overlay */
  }
  
  .slider-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .slider-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  }
  
  .slider-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  .slider-content h1 span {
    color: #ee5322;
}
  .slider-content p {
    font-size: 1.2rem;
  }
  /* slider.css *

/* ... (existing styles) */

.slider-content button {
    background: linear-gradient(to right, #cb1353, #ee5322); /* Example gradient colors */
    color: #fff; /* Example text color */
    padding: 10px 20px;
    font-size: 1rem;
    font-family: cursive;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    /* Add any additional styles as needed */
  }
  
  .slider-content button:hover {
    background: linear-gradient(to right, #ee5322, #cb1353); /* Example gradient colors on hover */
  }
  
  

/* slider.css */

/* ... (existing styles) */

.hero-slider .slider-item {
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
 
  /* AnimatedText.css */

.slider-item {
  text-align: center;
}

.main-title {
  margin: 0;

  position: relative;
  display: inline-block;
}

.blink-move {
  display: inline-block;
  animation: blinkMove 2s infinite alternate, changeColor 4s infinite;
}

@keyframes blinkMove {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes changeColor {
  0%, 100% {
    color: #ff6347; /* Start and end color */
  }
  50% {
    color: #cb1353; /* Middle color */
  }
}

 
@media (max-width: 768px) {
  .slider-content {
    top: 48%;
    left: 0%;
    transform: translate(0%, -50%);
  
}
.slider-content h1 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.slider-item {
  height: auto;
}
}